<template>
  <div id="">
    {{ errorData }}
  </div>
</template>
<script>
export default {
  name: "ServerErrorMessage",
  props: {
    errorData: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss" scoped></style>
